import React from "react";
import { Layout, Row, Col, Form, Input, Button, message } from "antd";
import { MailOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const { Footer } = Layout;

const CustomFooter = () => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      // Agregar los valores del formulario a Firestore

      const data = {
        comentarios: values.comentarios,
        email: values.email,
        titulo: values.titulo,
        revisado: false
      }
      await addDoc(collection(db, "apoyoNuevaCuenta"), data);
      message.success("¡Consulta enviada con éxito, un asesor se comunicara contigo!");
      form.resetFields(); // Resetea los campos después de enviar
    } catch (error) {
      console.error("Error al enviar la consulta: ", error);
      message.error("Hubo un error al enviar la consulta. Intenta de nuevo.");
    }
  };

  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#1dbf73",
        color: "white",
        marginTop: "20px",
        padding: "40px 20px",
      }}
    >
      <Row gutter={[16, 16]} justify="center">
        <Col span={24}>
          <strong>
            <a href="terminos-condiciones" style={{ color: 'white' }}>Términos y condiciones</a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="declaracion-privacidad" style={{ color: 'white' }}>Privacidad y confidencialidad</a>
          </strong>
          <span style={{ margin: "0 10px" }}>|</span>
          <strong>
            <a href="terminos-marketplace" style={{ color: 'white' }}>Información del marketplace</a>
          </strong>
        </Col>
        <Col span={24}>
          <p>
            Av. Juan Rulfo 1851 – 69 Fracc. Sonterra, C.P 76235, Querétaro, México.
          </p>
        </Col>
        <Col span={24}>
          <p style={{ fontSize: "20px", fontWeight: "bold" }}>CONTACTO</p>
          <p>
            ayuda@blizu.com.mx
            <br />
            +52 1 442 345 9936
          </p>
        </Col>
        <Col span={24}>
          <h3 style={{ color: "#fff", marginBottom: "20px" }}>¿Quieres ser un proveedor en Blizu?</h3>
          <p style={{ color: "#fff", marginBottom: "30px" }}>
            En Blizu puedes obtener múltiples beneficios por ofrecer tus productos, además de darle seguridad a los clientes.
          </p>
          <Form form={form} layout="vertical" onFinish={onFinish} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <Form.Item
              name="email"
              label={<span><MailOutlined style={{ marginRight: 8 }} /> Correo electrónico</span>}
              rules={[{ required: true, type: 'email', message: 'Por favor ingresa un correo válido.' }]}
            >
              <Input placeholder="tu-email@ejemplo.com" style={{ borderRadius: "5px" }} />
            </Form.Item>
            <Form.Item
              name="titulo"
              label={<span><QuestionCircleOutlined style={{ marginRight: 8 }} /> ¿Cuál es tu duda?</span>}
              rules={[{ required: true, message: 'Por favor ingresa un título para tu duda.' }]}
            >
              <Input placeholder="Título de tu consulta" style={{ borderRadius: "5px" }} />
            </Form.Item>
            <Form.Item
              name="comentarios"
              label="Comentarios"
              rules={[{ required: true, message: 'Por favor ingresa tus comentarios.' }]}
            >
              <Input.TextArea rows={4} placeholder="Escribe tus comentarios aquí..." style={{ borderRadius: "5px" }} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ borderRadius: "5px", background: "#fff", color: "#1dbf73" }}>
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={24}>
          <p style={{ marginTop: "40px" }}>
            Copyright © 2024 la presente plataforma es operada por la marca comercial "Blizu".
          </p>
        </Col>
      </Row>
    </Footer>
  );
};

export default CustomFooter;
